import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  templateUrl: './def.component.html',
  styleUrls: ['./def.component.scss'],
})
export class DefComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
  }
}
