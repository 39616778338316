<ion-content class="has-header" overflow-scroll="true">
  <ion-row>
    <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header
        title="references"
        secondTitle="bibliography"
        isBack="true"
        backTitle="Retour"
        backTo="ref"
        show="true"
      ></app-header>
      <div style="display: flex; justify-content: center">
        <ul>
          <li>Freud, S. Cinq leçons sur la psychanalyse, ed. Payot. (2001).</li>
          <li>
            Piaget, J. La naissance de l’intelligence chez l’enfant, ed.
            Delachaux et niestlé. (1970).
          </li>
          <li>
            Piaget, J. La construction du réel chez l’enfant, ed. Delachaux et
            niestlé. (1977).
          </li>
          <li>Bee et boyd, Les âges de la vie, ed.Pearson. (2003).</li>
          <li>
            Bouchard, C. Le développement global de l’enfant de 0 à 5 ans en
            contexte éducatif, Collection education de la petite enfance, ed.
            Presse de l’université du Québec. (2008).
          </li>
          <li>
            Bouchard, C. et fréchette, n. Le développement global de l’enfant de
            6 à 12 ans en contexte éducatif, (2011). Collection education de la
            petite enfance, ed. Presse de l’université du Québec.
          </li>
          <li>
            Erikson, e. Enfance et société, ed. Delachaux et niestlé. (1959).
          </li>
          <li>André, C. La force des émotions, ed. odile Jacob. (2003).</li>
          <li>
            André, C. & Lelord, f. Comment gérer les personnalités difficiles,
            ed.odile Jacob. (2000).
          </li>
          <li>André, C. L’estime de soi, ed. odile Jacob. (1999).</li>
          <li>
            Eva L. essa. A nous de jouer en service de garde éducatifs, ed. Les
            publications du Québec. (2010).
          </li>
          <li>Pieux, D. Un enfant heureux, ed. odile Jacob. (2010).</li>
          <li>
            Winnicott, D.w. La mère suffisamment bonne, ed. Petite bibliothèque
            Payot. (2006).
          </li>
          <li>
            Osterrieth, P. Introduction à la psychologie de l’enfant, ed. Puf.
            (1989).
          </li>
          <li>
            Cyrulnik, b. Les vilains petits canards, ed. odile Jacob. (2001).
          </li>
          <li>Cyrulnik, b. Un merveilleux malheur, ed. odile Jacob. (1999).</li>
          <li>Cyrulnik, b. Famille et résilience, ed.odile Jacob. (2010).</li>
          <li>
            Morel, C. ABC de la psychologie de l’enfant, ed. edition grancher.
            (1999).
          </li>
          <li>
            Tisseron, S. L’enfant au risque du virtuel, Collection inconscient
            et culture, ed. Dunod. (2012).
          </li>
          <li>
            Tisseron, S. Apprivoiser les écrans et grandir, ed. edition erès.
            (2013).
          </li>
          <li>
            Wallon, h. Les origines de la pensée chez l’enfant, ed. Puf. (1963).
          </li>
          <li>Vigotzky, L.S Pensée et langage, ed. La dispute. (1997).</li>
          <li>Le Petit Larousse de la psychologie ed Larousse (2013).</li>
        </ul>
      </div>
    </ion-col>
  </ion-row>
</ion-content>
