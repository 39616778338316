import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RefRoutingModule } from './ref-routing.module';
import { RefComponent } from './ref.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RefTheoComponent } from './ref-theo/ref-theo.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {  MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ProsComponent } from './pros/pros.component';
import { BiblioComponent } from './biblio/biblio.component';
import { DefComponent } from './def/def.component';
import { SharedModule } from 'src/app/shared/shared.module';
@NgModule({
  declarations: [
    RefComponent,
    RefTheoComponent,
    ProsComponent,
    BiblioComponent,
    DefComponent,
  ],
  imports: [
    CommonModule,
    RefRoutingModule,
    SharedModule,
    MatButtonToggleModule,
    MatTabsModule,
    MatTableModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RefModule {}
