<ion-content class="has-header" overflow-scroll="true">
  <ion-row>
    <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="theoreticalReferences" secondTitle="professionals" isBack="true" backTitle="back"
        backTo="ref" show="true"></app-header>
      <div>
        <ion-row>
          <ion-col size-md="8" offset-md="2" style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            ">
            <mat-button-toggle-group [(ngModel)]="period" aria-label="Période de vie" style="border-radius: 0px">
              <mat-button-toggle value="1">{{ "earlyInfancy" | translate | capitalizeFirstLetter }}</mat-button-toggle>
              <mat-button-toggle value="2">{{ "infancy" | translate | capitalizeFirstLetter }}</mat-button-toggle>
              <mat-button-toggle value="3">{{ "teenYoungAdult" | translate | capitalizeFirstLetter }}</mat-button-toggle>
            </mat-button-toggle-group>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="8" offset-md="2">
            <div *ngFor="let pro of pros">
              <div *ngIf="pro.value === period">
                <h4 style="margin-bottom: 8px; margin-top: 16px">
                  {{ "PROS."+pro.name | translate | capitalizeFirstLetter}}
                </h4>
                <p>{{ "PROS."+pro.desc | translate | capitalizeFirstLetter}}</p>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>
  </ion-row>
</ion-content>