<ion-content class="has-header" overflow-scroll="true">
  <ion-row>
    <ion-col size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="references" secondTitle="definitions" isBack="true" backTitle="back" backTo="ref"
        show="true"></app-header>
      <div style="display: flex; justify-content: center">
        <div class="container">
          <h3>{{ "devMeaning" | translate | capitalizeFirstLetter }}</h3>
          <p>
            {{ "devDef" | translate | capitalizeFirstLetter }}
          </p>

          <h3>{{ "fiveFundDevs" | translate | capitalizeFirstLetter }}</h3>
          <h4>{{ "fiveFundDevs1" | translate | capitalizeFirstLetter }}</h4>
          <p>
            {{ "socialAffectiveDev" | translate | capitalizeFirstLetter }}
          </p>

          <h4>{{ "fiveFundDevs2" | translate | capitalizeFirstLetter }}</h4>

          <p>
            {{ "cognitiveDev" | translate | capitalizeFirstLetter }}
          </p>

          <h4>{{ "fiveFundDevs3" | translate | capitalizeFirstLetter }}</h4>
          <p>
            {{ "langDev" | translate | capitalizeFirstLetter }}
          </p>

          <h4>{{ "fiveFundDevs4" | translate | capitalizeFirstLetter }}</h4>
          <p>
            {{ "motorDev" | translate | capitalizeFirstLetter }}
          </p>
          <h4>{{ "fiveFundDevs5" | translate | capitalizeFirstLetter }}</h4>
          <p>
            {{ "somaticDev" | translate | capitalizeFirstLetter }}
          </p>
        </div>
      </div>
    </ion-col>
  </ion-row>
</ion-content>