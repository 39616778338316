import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BiblioComponent } from './biblio/biblio.component';
import { DefComponent } from './def/def.component';
import { ProsComponent } from './pros/pros.component';
import { RefTheoComponent } from './ref-theo/ref-theo.component';
import { RefComponent } from './ref.component';

const routes: Routes = [
  { path: '', component: RefComponent },
  { path: 'biblio', component: BiblioComponent },
  { path: 'RefTheorique', component: RefTheoComponent },
  { path: 'pros', component: ProsComponent },
  { path: 'def', component: DefComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RefRoutingModule {}
