import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './biblio.component.html',
  styleUrls: ['./biblio.component.scss'],
})
export class BiblioComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {

  }
}
